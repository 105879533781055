import React, { Fragment, Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 
// import Cookie from 'js-cookie';
// import { Helmet } from 'react-helmet';
import './App.scss';
import { I18nProvider, LOCALES } from './i18n'; //language files
import ReactGA from 'react-ga'; //google analytics
import ScrollToTop from './component/ScrollToTop'; // scroll restoration
import ComingSoon from './component/pages/ComingSoon';
import ContestClosed from './component/pages/ContestClosed';
import Canvas from './component/canvas/Canvas';
import Header from './component/header/Header';
import Footer from './component/header/Footer';
import Homepage from './component/pages/Homepage';
import PrizeDetails from './component/pages/PrizeDetails';
import ThankYou from './component/pages/ThankYou';
import Rules from './component/pages/Rules';
import CookiesPage from './component/pages/CookiesPage';
// import YouLose from './component/pages/YouLose';
// import { translate } from './i18n/translate';
import { getContestState } from './util';
// import {useLocation} from "react-router-dom";

class App extends Component {

    state = {
      locale : window.reactInit.initlang,
      contestState : getContestState(),
      activeEN: window.reactInit.initlang===LOCALES.ENGLISH
    }

    //Language change
    langChangeHandle = lang => {
        if (process.env.REACT_APP_LANG_MODE==='switch'){
            this.setState({
                locale: lang
            })
    
            this.setState((prevState) => {
                return {activeEN: !prevState.activeEN}
            })
        } else {
            if (lang===LOCALES.ENGLISH){
                window.location = process.env.REACT_APP_EN_URL + window.location.pathname;
            } else {
                window.location = process.env.REACT_APP_FR_URL + window.location.pathname;
            }
        }

    }

    //checking for cookie
    componentDidMount(){
        //initiate google analytics
        const UAID = this.state.activeEN ? 'G-PV19LB4586' : '';
        // UA ids pulled manually from the gtm tags.
        ReactGA.initialize(UAID); // 
        ReactGA.pageview(window.location.pathname);
    }

    render(){
        console.log(this.state);
        return (
            <I18nProvider locale={this.state.locale}>
                <Router>
                    <ScrollToTop />
                    <Fragment>
                        {/* <Helmet>
                            <title>{this.state.locale === 'fr-CA' ? '' : 'Snack on with XBOX'}</title>
                        </Helmet> */}
                        <main className={this.state.locale}>
                        <a class="skip-to-content-link" href="#main">
                            Skip to content
                        </a>
                            <Canvas />
                            {/* <div id="glow"></div> */}
                            <Header langChangeHandle={this.langChangeHandle} activeEN={this.state.activeEN} />
                            <Switch>
                                {/* home page */}
                                { this.state.contestState===-1 &&
                                    <Route exact path="/" component={ComingSoon} />
                                }
                                { this.state.contestState===-1 &&
                                    <Route exact path="/home" component={ComingSoon} />
                                }
                                { this.state.contestState===0 &&
                                    <Route exact path="/" render={(props)=><Homepage {...props} lang={this.state.locale} />} />
                                }
                                { this.state.contestState===0 &&
                                    <Route exact path="/home" render={(props)=><Homepage {...props} lang={this.state.locale} />} />
                                }
                                { this.state.contestState===1 &&
                                    <Route exact path="/" component={ContestClosed} />
                                }
                                { this.state.contestState===1 &&
                                    <Route exact path="/home" component={ContestClosed} />
                                }

                                {/* { this.state.contestState===0 &&
                                    <Route exact path="/enter-contest" render={(props) => <EnterContest {...props} lang={this.state.locale} />} />
                                } */}
                                <Route exact path="/prize-details" render={(props) => <PrizeDetails {...props} lang={this.state.locale} />} />
                                <Route exact path="/thankyou" component={ThankYou} />
                                <Route exact path="/cookies" component={CookiesPage} />
                                {/* <Route exact path="/faq" component={Faq} /> */}
                                <Route exact path="/rules" render={(props) => <Rules {...props} lang={this.state.locale} />} />
                            </Switch>
                        </main>
                        <Footer lang={this.state.locale} />
                    </Fragment>
                </Router>
            </I18nProvider>
        );
    }
}

export default App;
