import {LOCALES} from '../locales.js';

export default {
    [LOCALES.FRENCH]: {

        //global
        'site.title' : '',
        'close': '',

        //header

        //nav
        'menu.item.home' : '',
        'menu.item.winner' : '',
        'menu.item.1': '',
        'menu.item.2': '',
        'menu.item.3': '',
        'menu.item.4': '',
        'menu.item.5': '',
        
        'contact-email': '',

        //footer
        'footer.link1' : '',
        'footer.link2' : '',
        'footer.link3' : '',
        'footer.link4' : '',
        'footer.link5' : '',

        //homapge
        'homepage.gameon' : '',
        'homepage.unlock': '',
        'homepage.prize1' : '',
        'homepage.prize' : '',
        'homepage.pluscash': '',
        'homepage.pluscash2': '',
        'homepage.pluscash3': '',
        'homepage.prepluscash': '',
        'homepage.preprize': '',
        'homepage.cta' : '',
        'homepage.howtoenter' : '',
        'homepage.step1head' : '',
        'homepage.step1' : '',
        'homepage.step2head' : '',
        'homepage.step2' : '',
        'homepage.step3head' : '',
        'homepage.step3' : '',
        'homepage.offerhead': '',
        'homepage.prod1': '',
        'homepage.prod1url': '',
        'homepage.prod2': '',
        'homepage.prod2url': '',
        'homepage.prod3': '',
        'homepage.prod3url': '',
        'homepage.offercta': '',

        'contestClosedHead1' : '',
        'contestClosedHead' : '',
        'contestClosedText' : '',
        // 'contestClosedLink' : '',
        // 'contestClosedCTA' : '',

        'enterCta' : '',
        'enterNow' : '',

        'legal' : '',
        'legal.link' : '',

        'comingsoon' : '',

        //enter contest page
        'step1': '',
        'enterPin': '',
        'enterKeyDescription': '',
        'enterKeyLink': '',
        'enterKeySmall': '',
        'popupBodytext' : '',
        'popupBodylink' : '',
        'pin': '',
        'nextStep': '',

        'step2': '',
        'enterInfo': '',
        'enterInfoDescription': '',
        'firstName': '',
        'lastName': '',
        'emailAddress': '',
        'phone': '',
        'infoForm.age': '',
        'infoForm.rules' : '',
            'infoForm.rules.link1' : '',
            'infoForm.rules2' : '',
            'infoForm.rules.link2' : '',
            'infoForm.rules3' : '',
        'infoForm.optin' : '',
        'requiredFields' : '',
        'submit': '',

        //winning page
        'congratulation' : '',
        'winningPage.heading' : '',
        'winningPage.body' : '',
        'winningPage.question' : '',
        'winningPage.answer' : '',
        'winningPage.cta' : '',
        'winningPage.field1': '',
        'winningPage.field2': '',
        'winningPage.field3': '',
        'winningPage.field4': '',
        'winningPage.field5': '',

        'winningPage.heading1' : '',
        'winningPage.heading2' : '',
        'winningPage.body2' : '',
        'winningPage.legal' : '',

        'winningPage.heading3' : '',
        'winningPage.body3' : '',

        'winningPage.heading4' : '',
        'winningPage.body4': '',

        'address1': '',
        'address2': '',
        'city': '',
        'prov': '',
        'postal': '',

        // 'winningPage.heading2' : '',
        'download': '',

        //losing page
        'tryAgain' : '',
        'tryAgain.body' : '',
        'tryAgain.btn' : '', 
        'tryAgain.heading2' : '',
        'tryAgain.body2' : '',
        'tryAgain.body3' : '',

        //Prize Details
        'prizeDetails' : '',

        'prizeDetails-heading1' : '',
        'prizeDetails-subheading1' : '',
        'prizeDetails-body1' : '',
        "prizeDetails-legal": '',
 
        'prizeDetails-heading2' : '',
        'prizeDetails-subheading2' : '',
        'prizeDetails-body2' : '',
        'prizeDetails-prod1heading' : '',
        'prizeDetails-prod2heading' : '',
        'prizeDetails-prod3heading' : '',
        'prizeDetails-prod4heading' : '',
        'prizeDetails-prod1' : '',
        'prizeDetails-prod2' : '',
        'prizeDetails-prod3' : '',
        'prizeDetails-prod4' : '',
        

        // Participating Products
        'products' : '',
        'products.group1' : '',
        
        //faq page
        'faq-heading-text1': '',
            'faq-heading-link': '',
        'faq-heading-text2': '',

        'q1': '',
        'a1': '',
        
        'q2': '',
        'a2': '',
        
        'q2-b' : '',
        'a2-b' : '',
        'a2-c': '',
        
        'q3': '',
        'a3': '',
        
        'q4': '',
        'a4': '',

        'q5': '',
        'a5': '',
        'a5a': '',
        'a5b': '',

        'q6': '',
        'a6': '',
            'a6-link': '',
            'a6-bottom': '',

        'q7': '',
        'a7': '',

        'q8': '',
        'a8': '',

        'q9': '',
        'a9': '',
        'a9-c': '',
        'a9-2': '',

        'a9-3': '',
        'a9-3b': '',
        'a9-3c': '',

        // 'q10': '',
        // 'a10': '',
        // 'a10-link': '',

        // 'q11': '',
        // 'a11': '',
        // 'a11-2' : '',
        // 'a11-3' : '',

        //Rules page
        'rules-page-heading': '',
    }
}