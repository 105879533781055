import React, { Component, Fragment } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
// import Cookie from 'js-cookie';
import Recaptcha from 'react-google-invisible-recaptcha';
import InfoForm from '../forms/InfoForm';
// import ReactGA from 'react-ga'; //google analytics

//import css
import '../../styles/pages/_enter-contest.scss';

const RECAPTCHA_SITE_KEY = '6LcWrjYpAAAAAK5kFBXN7iXq1zp3PMl29WT_kL7k';

class EnterContest extends Component{

    state = {
        step: 1,
        sku1 : "",
        sku2 : "",
        sku3 : "",
        fName : "",
        lName : "",
        email: "",
        prov: "",
        age: false,
        rules: false,
        optin: false,
        pinFormActive: true,
        loading: false,
        reCapToken: "",
        processing: false,

        fNameError: "",
        lNameError: "",
        emailError: "",
        provError: "",
        ageError: "",
        rulesError: "",
        optinError: "",
        skuError: "",

        timestamp: ""
    }

    //go to next step
    nextStep = (e) => {
        e.preventDefault();
        const { step, pin } = this.state;
        
        if (pin !== ""){
            this.setState({
                step: step + 1
            })
        }
    }

    //go back
    prevState = () => {
        //const { step } = this.state;
        this.setState({
            step: 1
        })
    }

    //input fields on change or on type
    handleChange = input => e => {
        this.setState({
            [input] : e.target.value
        })

        //setting error to be blank once start typing
        const errName = input.indexOf('sku')>-1 ? 'sku' : input;
        const errorState = `${errName}Error`;
        this.setState({
            [errorState] : ""
        })
    }

    //check box on click
    handleCheckbox = e => {
        this.setState({
            [e.target.name] : e.target.checked
        })

        ////setting error to be blank once clicked
        const errorState = e.target.name + 'Error';
        this.setState({
            [errorState] : ""
        })
    }

    //close warning from PinForm 
    inputPinClickHandler = () => {
        this.setState({
            step: 2
        })
    }

    // PIN input field onkeypress 
    inputKeypressHandler = () =>{
        if (this.state.step !== 2){
            this.setState({
                step: 2
            })
            console.log('step update');
        }
    }

    //error popup modal close on info form
    closeWarningfromInfoForm = () =>{
        this.setState({
            step: 1
        })
    }


    //validate form
    validateForm = () => {
        console.log('validation is called')
        const { fName, lName, email, age, rules, optin, prov } = this.state;
        let fNameError = "";
        let lNameError = "";
        let emailError = "";
        let provError = "";
        let ageError = "";
        let rulesError = "";
        let optinError = "";

        //getting language cookei
        // const langPreferance = this.props.lang;


            fName ? fNameError = "" : fNameError = "Please enter your first name";
            lName ? lNameError = "" : lNameError = "Please enter your last name";
            age ? ageError = "" : ageError = "Please confirm that you are the age of majority in the province/territory where you reside";
            // prov ? provError = "" : provError = "Please enter a valid province";
            rules ? rulesError = "" : rulesError = "Please confirm that you have read and comply with the Contest rules and regulations and the Mondelēz Canada Privacy Policy";
            optin ? optinError = "" : optinError = "Please confirm that you consent";
            if(email && email.includes('@') && email.includes('.') ) {emailError = ""; } else {  emailError = "Please provide a valid email address"; }
            if (prov==='Ontario') {
                provError = "";
            } else if (!prov) {
                provError = "Please enter a valid province";
            } else {
                provError = "This contest is only open to residents of Ontario";
            }
            console.log('prov?',provError);


        if (fNameError || lNameError || emailError || provError || ageError || rulesError || optinError){
            this.setState({
                fNameError,
                lNameError,
                emailError,
                provError,
                ageError,
                rulesError,
                optinError
            })
            return false;
        }

        return true;
    }

    //handle recaptcha
    handleSubmitInfo = e => {
        e.preventDefault();
        const { processing } = this.state;
        if (processing) {
            console.log('processing'); return;
        }
        const recaptcha = this.recaptcha;
        const validateForm= this.validateForm();
        if (validateForm === true){
            this.setState({processing:true});
            recaptcha.execute().then(token => {
                console.log(token)
                this.setState({ reCapToken: token })
                this.handleSubmit(); 
            });
        }
    }

    verifyCallback = res =>{ 
        // todo. why isn't this being called?
        // possibly causing a Timeout error?
        console.log('verify captcha callback',res);
    }

    handleSubmitSku = e => {
        e.preventDefault();
        const { processing } = this.state;
        if (processing) {
            console.log('processing'); return;
        }
        const recaptcha = this.recaptcha;
        const validSkus = this.validateSkus();
        
        if (validSkus) {
            this.setState({processing:true});
            recaptcha.execute().then(token => {
                console.log(token)
                this.setState({ reCapToken: token })
                this.handleSubmit(); 
            });
        }
    }

    //handle form submit
    handleSubmit = e => {
        //e.preventDefault();
        console.log('after submission')

        const { fName, lName , prov, email, age, rules, optin, reCapToken } = this.state;

            //loading state on
            this.setState({loading: true})

            // axios.post('https://xbox-mondelez-contest.herokuapp.com/entries', {
           axios.post(process.env.REACT_APP_BACKEND_URL+'/entries', {
                "email": email,
                "firstname": fName,
                "lastname": lName,
                "prov": prov,
                "age": age,
                "rules": rules,
                "optin": optin,
                "token" : reCapToken,
                "lang" : this.props.lang
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({loading: false,processing:false})
                console.log('res', res)
                if ( res.status === 200 ){
                    this.setState({ step: 3, timestamp: res.data.timestamp }, () => { this.props.history.push(`?status=winner`); window.scrollTo(0, 0); })
                } else if ( res.status === 202 ){
                    console.log('redirect');
                    this.setState({ step: 4 }, () => { window.scrollTo(0, 0); })
                } 
            }).catch(err => {
                const { step } = this.state;
                this.setState({loading: false,processing:false})
                //console.log('currently on step',step);
                if (step===3||step===4) return;
                console.log('Error', err.response)
                if ( err.response.status === 411 ) { // missing fields
                    this.setState({ step: 8 })
                } else if( err.response.status === 412 ){ // invalid email
                    this.setState({emailError : "Please enter a valid email", step: 1})
                } else if ( err.response.status === 413 ) { // invalid province
                    this.setState({provError : "Please enter a valid province", step: 1})
                } else if ( err.response.status === 429 ) {
                    this.setState({step: 5}); // too many from same ip
                } else if ( err.response.status === 451 ) {
                    this.setState({step: 6}); // max per day
                } else if ( err.response.status === 452 ) {
                    this.setState({step: 6}); // max per contest
                } else if ( err.response.status === 400 ) {
                    this.setState({step: 7}); // internal error
                } else if ( err.response.status === 401 ) {
                    this.setState({step: 10}); // captcha error
                } else { // catchall
                    this.setState({step: 8}); // catch all error
                }
            })
    }

    componentDidMount(){
        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render(){

        const { step, loading } = this.state;
        const { sku1, sku2, sku3, fName, lName , prov, email, age, rules, optin } = this.state;
        const values = { sku1, sku2, sku3, fName, lName, prov, email, age, rules, optin };
        const { fNameError, lNameError, emailError, provError, ageError, rulesError, optinError, skuError } = this.state;
        const errorMessages = { fNameError, lNameError, emailError, provError, ageError, rulesError, optinError, skuError };
        const langPreferance = this.props.lang;

        switch(step){
            default: 
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleSubmitInfo={this.handleSubmitInfo} validateForm={this.validateForm} loading={loading} lang={langPreferance} />
                        <Recaptcha ref={ el => {this.recaptcha = el} } sitekey={RECAPTCHA_SITE_KEY} verifyCallback={this.verifyCallback} />
                    </Fragment>
                )

            // case 2:
            //     return(
            //         <Fragment>
            //             <PinForm values={values} nextStep={this.handleSubmitSku} handleChange={this.handleChange} lang={langPreferance} errorMessage={skuError} />
            //             <Recaptcha ref={ el => {this.recaptcha = el} } sitekey={RECAPTCHA_SITE_KEY} verifyCallback={this.verifyCallback} />
            //         </Fragment>
            //     );

            // case 2:
            //     return(
            //         <YouWon timestamp={timestamp} />
            //     )

            case 4:
                return(
                    <Redirect to="/thankyou" />
                )

            // case 5: 
            //     return(
            //         <Fragment>
            //             <PinForm values={values} nextStep={this.handleSubmitSku} handleChange={this.handleChange} errorMessage={langPreferance === 'fr-CA' ? `Code non valide. Le code inscrit ne doit pas déjà avoir été utilisé.` : `The code is not valid: Code must be unused`} inputPinClickHandler={this.inputPinClickHandler} inputKeypressHandler={this.inputKeypressHandler} lang={langPreferance} />
            //             <Recaptcha ref={ el => {this.recaptcha = el} } sitekey={RECAPTCHA_SITE_KEY} verifyCallback={this.verifyCallback} />
            //         </Fragment>
            //     )

            // case 6: 
            //     return(
            //         <Fragment>
            //             <PinForm values={values} nextStep={this.handleSubmitSku} handleChange={this.handleChange} errorMessage={langPreferance === 'fr-CA' ? 'Code non trouvé dans notre base de données.' : 'The code you have entered was not found'} inputPinClickHandler={this.inputPinClickHandler} inputKeypressHandler={this.inputKeypressHandler} lang={langPreferance} />
            //             <Recaptcha ref={ el => {this.recaptcha = el} } sitekey={RECAPTCHA_SITE_KEY} verifyCallback={this.verifyCallback} />
            //         </Fragment>
            //     )

            // case 7:
            //     return(
            //         <Fragment>
            //             <PinForm values={values} nextStep={this.handleSubmitSku} handleChange={this.handleChange} errorMessage={langPreferance === 'fr-CA' ? 'Code erroné.' : 'The code you have entered is incorrect'} inputPinClickHandler={this.inputPinClickHandler} inputKeypressHandler={this.inputKeypressHandler} lang={langPreferance} />
            //             <Recaptcha ref={ el => {this.recaptcha = el} } sitekey={RECAPTCHA_SITE_KEY} verifyCallback={this.verifyCallback} />
            //         </Fragment>
            //     )

            // case 4:
            //     return(
            //         <Fragment>
            //             <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleSubmitInfo={this.handleSubmitInfo} validateForm={this.validateForm} invalidEntry={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
            //         </Fragment>
            //     )

            case 5:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleSubmitInfo={this.handleSubmitInfo} validateForm={this.validateForm} tooManyReqIp={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                    </Fragment>
                )

            case 6:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleSubmitInfo={this.handleSubmitInfo} validateForm={this.validateForm} tooManyReqEmail={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                    </Fragment>
                )

            case 7:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleSubmitInfo={this.handleSubmitInfo} validateForm={this.validateForm} internalError={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                    </Fragment>
                )

            case 8:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleSubmitInfo={this.handleSubmitInfo} validateForm={this.validateForm} dbError={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                    </Fragment>
                )

            case 9:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleSubmitInfo={this.handleSubmitInfo} validateForm={this.validateForm} badReq={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                    </Fragment>
                )
            
            case 10:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleSubmitInfo={this.handleSubmitInfo} validateForm={this.validateForm} captchaError={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                    </Fragment>
                )
        }
    }
}

export default withRouter(EnterContest);