import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

//translate file
import { translate } from '../../i18n/translate';

import { getContestState } from '../../util';
import closeBtn from '../../images/close header.png';

class Nav extends Component{

    render(){

        // const siteOrigin = window.location.origin;
        const disabled = !this.props.menuOpen;
        const contestState = getContestState();
        
        return(
            <Fragment>
                <div className={`nav-wrapper ${this.props.className}`}>
                    <div className="container">
                        <button onClick={this.props.menuClickHandler} style={{display:'block'}} aria-label="Menu" id="nav-close-btn">
                            <img src={closeBtn} alt="close menu"/>
                        </button>
                    </div>
                    <div className='nav-container'>
                        <ul className="nav-list">
                            <li className="nav-list__item"><NavHashLink activeClassName="selectedLink" to='/home#' tabIndex={disabled?-1:0} onClick={this.props.menuClickHandler}>{translate('menu.item.home')}</NavHashLink></li>
                            { contestState===0 && 
                                <li className="nav-list__item"><NavHashLink activeClassName="selectedLink" to='/home#enter-contest' id="menu-enterContest" tabIndex={disabled?-1:0} onClick={this.props.menuClickHandler}>{translate('menu.item.1')}</NavHashLink></li>
                            }
                            <li className="nav-list__item"><NavLink exact activeClassName="selectedLink" to='/prize-details' tabIndex={disabled?-1:0} onClick={this.props.menuClickHandler}>{translate('menu.item.2')}</NavLink></li>
                            {/* <li className="nav-list__item"><NavLink exact activeClassName="selectedLink" to='/products' tabIndex={disabled?-1:0} onClick={this.props.menuClickHandler}>{translate('menu.item.5')}</NavLink></li> */}
                            {/* <li className="nav-list__item"><NavLink exact activeClassName="selectedLink" to='/faq' tabIndex={disabled?-1:0} onClick={this.props.menuClickHandler}>{translate('menu.item.3')}</NavLink></li> */}
                            <li className="nav-list__item"><NavLink exact activeClassName="selectedLink" to='/rules' tabIndex={disabled?-1:0} onClick={this.props.menuClickHandler}>{translate('menu.item.4')}</NavLink></li>
                        </ul>   
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Nav;