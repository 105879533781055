import React, { Component, Fragment } from 'react';
import { gsap, Power3 } from 'gsap';
// import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';
// import InjectIntl from 'react-intl-inject';

//images
import puck1 from '../../images/puck1.png';
import puck2 from '../../images/puck2.png';
import puck3 from '../../images/puck3.png';

//language file
import { translate } from '../../i18n/translate';

class ThankYou extends Component{

    componentDidMount(){
        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        gsap.from("h1, .pucks-container, p", .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut,
            stagger: .2
        })
    }

    render(){

        return(
            <Fragment>
                <div id="main" className="main-wrapper thankyou">
                    <section>
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 offset-md-3 text-center">
                                    <div className="you-win">
                                        <h1>{translate('tryAgain')}</h1>
                                        <div className="pucks-container">
                                            <img src={puck1} alt="puck" id="puck1" />
                                            <img src={puck2} alt="puck" id="puck2" />
                                            <img src={puck3} alt="puck" id="puck3" />
                                        </div>
                                        <p>{translate('tryAgain.body')}</p>
                                        <p className="small">{translate('tryAgain.body2',{u: chunks => <a href="/rules">{chunks}</a>})}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }
}

export default ThankYou;