import React, { Component } from 'react';

import '../../styles/_global.scss';

class Canvas extends Component{

    componentDidMount() {
        // var pstate = this.particles.state;
        // console.log(this);
        // const canvas = this.canvas;
        // const ctx = canvas.getContext("2d");
        // const floor = this._floorimage;
        // const fade = this._fadeimage;
        // const bg = this.bg;

        /*
        floor.onload = () => {
            // console.log(bg);
            bg.style.width = document.body.clientWidth;
            bg.style.height = document.body.clientHeight;
            //ctx.drawImage(floor, 0, canvas.height-floor.height);
            //ctx.drawImage(fade, 0, 0);
            // const pattern = ctx.createPattern(img, 'repeat');
            // ctx.fillStyle = pattern;
            // ctx.fillRect(0, 0, canvas.width, canvas.height);
            //ctx.font = "40px Courier"
            //ctx.fillText(this.props.text, 210, 75)
        } */
    }

    render(){
        return(
            <div id="bg">
              {/* <div></div>   */}
            </div>
        )
    }
}

export default Canvas;