import React, { Component, Fragment } from 'react';
import { gsap, Power3 } from 'gsap';
// import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';

// import '../../styles/pages/_homepage.scss';

//import image
import { translate } from '../../i18n/translate';

import oreo from '../../images/oreo.png';
import puck1 from '../../images/puck1.png';
import puck2 from '../../images/puck2.png';
import puck3 from '../../images/puck3.png';

class ContestClosed extends Component {

    state = {
        isHover : false
    }

    //Handle Mouse over event for enter now button
    mouseEnter = () =>{
        this.setState(prevState =>{
            return {isHover: !prevState.isHover}
        })
    }

    //HANDLE MOUSE LEAVE ON THE ENTER NOW BUTTON
    mouseLeave = () =>{
        this.setState(prevState =>{
            return {isHover: !prevState.isHover}
        })
    }


    componentDidMount(){

        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);


        //GSAP animation for the heading elements
        gsap.from('#oreo', 2, {
            x: -10,
            y: '-50vh',
            rotation: -20,
            ease: Power3.easeOut, 
            delay: .5
        });
        gsap.from('#puck1', 2, {
            x: '-100vw',
            y: 100,
            rotation: -20,
            ease: Power3.easeOut, 
            delay: .5
        });
        gsap.from('#puck2', 2, {
            x: '50vw',
            y: window.innerWidth >= 576 ? -200 : 50,
            rotation: 20,
            ease: Power3.easeOut, 
            delay: .5
        });
        gsap.from('#puck3', 2, {
            x: window.innerWidth >= 576 ? '100vw' : '-100vw',
            y: 20,
            rotation: -40,
            ease: Power3.easeOut, 
            delay: .5
        });
        gsap.from(".big-p, .title-pre, .prize-money, .title-post, .lil-p", .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut,
            stagger: .2, 
            delay: 2
        });
    }

    componentWillUnmount(){
        
    }

    render(){
        return (
            <Fragment>
                <div id="main" className="main-wrapper">
                    <div className="container home">
                        <div className="row align-items-center">
                            <div className="col-12 col-sm-6 order-sm-2 home-pucks">
                                <p className="big-p d-block d-sm-none text-center">{translate('comingsoon')}</p>
                                <div className="pucks-container">
                                    <img src={oreo} alt="Oreo" id="oreo" />
                                    <img src={puck1} alt="Fan Fair Puck" id="puck1" />
                                    <img src={puck2} alt="Skills Puck" id="puck2" />
                                    <img src={puck3} alt="All-Star Puck" id="puck3" />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 order-sm-1 home-text-container text-center"> {/*before it was col-md-6*/}
                                <div className="heading-text">
                                    <p className="big-p d-none d-sm-block">{translate('comingsoon')}</p>
                                    <span className='title-pre'>{translate('homepage.gameon')}</span>
                                    <h1 className="prize-money" ref={el => {this.headingTwo = el}}>
                                        <span>{translate('homepage.prize', {small:chunks=><small>{chunks}</small>})}</span>
                                        <span>{translate('homepage.prize1', {small:chunks=><small>{chunks}</small>})}</span>
                                    </h1>
                                    <h2 className='title-post'>{translate('homepage.unlock', {small:chunks=><small>{chunks}</small>})}</h2>
                                    <p className="lil-p" >{translate('comingsoon.desc')}</p>
                                </div>
                          
                            </div>
                        </div>
                    </div>
                    <div className="container disclaimer-wrap">
                        <div className="row">
                            <div className="col-md-12 disclaimer">
                                <p className='homepage-legal'>{translate('homepage.legal')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Fragment>
        );
    }
}

export default ContestClosed;