import React, { Component, Fragment } from 'react';
import { gsap, Power3 } from 'gsap';
// import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';
// import { LOCALES } from '../../i18n'; //language files
import '../../styles/pages/_homepage.scss';
import EnterContest from './EnterContest';
import { HashLink } from 'react-router-hash-link';

//import image
import { translate } from '../../i18n/translate';

import oreo from '../../images/oreo.png';
import puck1 from '../../images/puck1.png';
import puck2 from '../../images/puck2.png';
import puck3 from '../../images/puck3.png';

class Homepage extends Component {

    state = {
        isHover : false
    }

    

    componentDidMount(){
        
        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        //GSAP animation for the heading elements
        gsap.from('#oreo', 2, {
            x: -10,
            y: '-50vh',
            rotation: -20,
            ease: Power3.easeOut, 
            delay: .5
        });
        gsap.from('#puck1', 2, {
            x: '-100vw',
            y: 100,
            rotation: -20,
            ease: Power3.easeOut, 
            delay: .5
        });
        gsap.from('#puck2', 2, {
            x: '50vw',
            y: window.innerWidth >= 576 ? -200 : 50,
            rotation: 20,
            ease: Power3.easeOut, 
            delay: .5
        });
        gsap.from('#puck3', 2, {
            x: window.innerWidth >= 576 ? '100vw' : '-100vw',
            y: 20,
            rotation: -40,
            ease: Power3.easeOut, 
            delay: .5
        });
        gsap.from(".title-pre, .prize-money, .title-post, .learn-more-container", .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut,
            stagger: .2, 
            delay: 2
        });

    }

    componentWillUnmount(){
        
    }

    render(){
        // const langPreferance = this.props.lang;
        
        return (
            <Fragment>
                <div id="main" className="main-wrapper">
                    <div className="container home">
                        <div className="row bg-home align-items-center">
                            <div className="col-12 col-sm-6 order-sm-2 home-pucks">
                                <div className="pucks-container">
                                    <img src={oreo} alt="Oreo" id="oreo" />
                                    <img src={puck1} alt="Fan Fair Puck" id="puck1" />
                                    <img src={puck2} alt="Skills Puck" id="puck2" />
                                    <img src={puck3} alt="All-Star Puck" id="puck3" />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 order-sm-1 home-text-container text-center"> 
                                <div className="heading-text">
                                    <span className='title-pre'>{translate('homepage.gameon')}</span>
                                    <h1 className="prize-money">
                                        <span>{translate('homepage.prize', {small:chunks=><small>{chunks}</small>})}</span>
                                        <span>{translate('homepage.prize1', {small:chunks=><small>{chunks}</small>})}</span>
                                    </h1>
                                    <h2 className='title-post'>{translate('homepage.unlock', {small:chunks=><small>{chunks}</small>})}</h2>
                                </div>
                               
                                
                            </div>
                        </div>

                        <div className="row learn-more-container">
                            <small>{translate('legal')}</small>
                            <HashLink to="#enter-contest" smooth>
                                {translate('homepage.cta')}
                            </HashLink>
                        </div>
                       
                        <div id="enter-contest">
                            <EnterContest />
                        </div>
                        <p className='homepage-legal'>{translate('homepage.legal')}</p>
                    </div>
                   
                </div>
                
            </Fragment>
        );
    }
}

export default Homepage;