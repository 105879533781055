import React, { Component, Fragment } from 'react';
import { HashLink } from 'react-router-hash-link';
import { gsap, Power3 } from 'gsap';
// import InjectIntl from 'react-intl-inject';
import ReactGA from 'react-ga';

//import css
import '../../styles/pages/_prize-details.scss';

//language file
import { translate } from '../../i18n/translate';

import { getContestState } from '../../util';

import ticketImage1_en from '../../images/skills-tickets.png';
import ticketImage2_en from '../../images/allstar-tickets.png';
import ticketImage3_en from '../../images/fan-fair-tickets.png';

class PrizeDetails extends Component{

    componentDidMount(){

        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        //GSAP animation for the heading elements
        const { heading, prize1, prize1desc, prize2, prize2desc } = this;
        gsap.from([heading, prize1, prize1desc, prize2, prize2desc], .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut,
            stagger: .2
        })
    }

    render(){
        // const langPreferance = this.props.lang;
        const contestState = getContestState();
        return(
            <Fragment>
                <div id="main" className="main-wrapper prize-details">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <div className="page-heading">
                                        <h1 ref={el =>{this.heading = el}}>{translate('prizeDetails')}</h1>
                                    </div>
                                    <div className="row prize-details__text-section grand-prize">
                                        <div className="col-md-12" ref={el => {this.prize1desc = el}}>
                                            
                                            <div>
                                                <img src={ticketImage1_en} alt="Skills ticket" />
                                                <h4>{translate('prizeDetails-prod1heading')}</h4>
                                               <p>{translate('prizeDetails-prod1', {br : <br />})}
                                               </p> 
                                            </div>
                                            <div>
                                                <img src={ticketImage2_en} alt="All-star ticket" />
                                                <h4>{translate('prizeDetails-prod2heading')}</h4> 
                                                <p>{translate('prizeDetails-prod2', {br : <br />})}</p>
                                            </div>
                                            <div>
                                                <img src={ticketImage3_en} alt="Fan-fair ticket" />
                                                <h4>{translate('prizeDetails-prod3heading')}</h4>
                                                <p>{translate('prizeDetails-prod3', {br : <br />})}</p>
                                            </div>
                                           
                                           
                                            <p className="small">{translate('tryAgain.body2',{u: chunks => <a href="/rules">{chunks}</a>})}</p>
                                        </div>
                                    </div>
                                    
                                    { contestState===0 && <div className="text-center">
                                            <HashLink exact to="/home#enter-contest" className="enter-btn">
                                                {translate('enterNow')}
                                            </HashLink> 
                                        </div> }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }
}

export default PrizeDetails;